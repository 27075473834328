*, *:after, *:before {
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', Helvetica, arial, sans-serif;
}

.viewport-slider-item {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.viewport-slider-button {
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  opacity: .5;
  padding: 12px;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity .35s;
}

.viewport-slider-button:hover {
  opacity: 1;
}

.viewport-slider-paginator {
  background-color: #ccc;
  border: 1px solid #fff;
  box-shadow: 0 0 2px #ccc;
}

.viewport-slider-paginator-bullet {
  background: #fff;
  cursor: pointer;
  opacity: .4;
  transition: opacity .35s;
}

.viewport-slider-paginator-bullet:hover {
  opacity: .6;
}

.viewport-slider-paginator-bullet.is-active {
  opacity: 1;
}

.content {
  font-size: 5.5em;
  text-align: center;
  font-weight: bold;
  letter-spacing: -1px;
  margin: 0;
}

.has-overlay-peach::after {
  content: '';
  left: 0;
  background-color: #D5AF86;
  height: 100%;
  opacity: .7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.has-overlay-peach > div {
  z-index: 2;
}

.has-overlay-blue::after {
  content: '';
  left: 0;
  background-color: #DEE4F5;
  height: 100%;
  opacity: .7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.has-overlay-blue > div {
  z-index: 2;
}

.has-overlay-green::after {
  content: '';
  left: 0;
  background-color: #a2d7c7;
  height: 100%;
  opacity: .7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.has-overlay-green > div {
  z-index: 2;
}
